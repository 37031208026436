import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function optimizeCloudinaryImage(url: string | null | undefined, width: number) {
  if (url?.includes("cloudinary")) {
    let replacement = `w_${width},f_auto`;
    if (url.includes(".gif")) {
      replacement = `${replacement},fl_lossy`;
    }
    return url.replace("upload/", `upload/${replacement}/`);
  }
  return url;
}

/**
 * Combines twMerge and clsx to allow for overriding tw classes and object synyax via clsx.
 * Pulled from https://github.com/shadcn-ui/ui/blob/main/apps/www/lib/utils.ts
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
